header,main,#main_navi,li, li a{
transition: all .3s ease-in-out;
-moz-transition: all .3s ease-in-out;
 -webkit-transition: all .3s ease-in-out;
}

.logo{
transition: all .3s .3s ease-in-out;
-moz-transition: all .3s .3s ease-in-out;
 -webkit-transition: all .3s .3s ease-in-out;
}

body #startseite_anker{
  display:none;
}

#main_navi_mobile{opacity:0;top:0px;left:0px;overflow:hidden;width:1px;height:1px;}

body{overflow-x:hidden;position:relative;font-family: 'Open Sans', sans-serif;}

body.page.impressum .main,
body.page.agb .main,
body.page.datenschutz-cookies .main,
body.single-product #page{display:none;}
body:not(.home){
  .addthis-smartlayers,.contact_widget--wrapper{display:none!important;}
}

body.home{
  .featherlight{
    background: rgba(0,0,0,.8);
  }

}

.main{padding:0px;margin-top:196px;}
.row{margin-left:0px;margin-right:0px;}
.content_full{width:100%;position:relative;}
section:first-of-type{padding-top:0px;}
section{padding-top:66px;padding-bottom:80px;}

.full-width{
  width:100%;
  min-width:100%;
.wpb_single_image{
  .vc_single_image-wrapper,.vc_figure,img{
  width:100%;
  min-width:100%;

}
}
 &.nmargin{
   margin-top: -166px;
   display:inline-block;
 }

}

.z-top{
  z-index:999;
}

html.mm-front body  #mm-blocker{z-index:2;}
html.mm-front body .mm-menu {
    z-index: 3;
}

header.scrolled{
  transform: translateZ(0);
 -moz-transform: translatez(0);
 -ms-transform: translatez(0);
 -o-transform: translatez(0);
 -webkit-transform: translateZ(0);
 -webkit-font-smoothing: antialiased;
-webkit-transform: translateZ(0);
position: fixed;
top: 0;
left: 0;
width: 100%;
z-index: 2;
background-color: #fff;
height: 114px;
z-index:2;
.logo{
  height:0px;
  overflow:hidden;
  opacity:0;
}

#main_navi{
  text-align: center;
  li{
    float:none;
    display:inline-block;
    margin-top:50px;
    &.big{
    /*  margin-right:22px;*/
    }
  }
}

}

main.scrolled{
  margin-top: 115px;
}

/*
.nav-primary{
  opacity:0;
  position:absolute;
  top:0;
}*/

  .cta-button {
    width:100%;
    text-align:center;
    a{
    background-color:transparent;
    border:1px solid $primary;
    border-radius:5px;
    color:$primary;
    width:255px;
    height:50px;
    font-size:24px;
    line-height:50px;
    text-transform:uppercase;
    font-weight:bold;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top:56px;
  }
  &.cta-button-3{
    a{
        width:528px;
    }
  }
}

#jetzt-konfigurieren-und-bestellen{
  .cta-button a{
    background-color:$primary;
    color:#fff;
  }
}

.stats-desc{

  text-align:center;
  .stats-number,.counter_suffix{
    display:inline;
    color:$primary;
    font-weight:700!important;
    font-size: 54px!important;
  }
}

h3{
  font-weight: 700;
  font-size: 24px;
}

.quote_line{
 margin-top:110px;
p{
  font-family: 'Droid Serif', serif;
  font-size: 16px;
  text-align: center;
    color:rgb(128,128,128);
}
}
.quote_link{
  font-size: 16px;
  text-align: center;
  font-weight: 300;
  a{
    color:rgb(128,128,128);
    text-decoration: none;
  }
  a:after{
    content:"";
    width:19px;
    height: 16px;
    background-image: url("../images/ext_link.png");
    background-repeat: no-repeat;
    display:inline-block;
    margin-left:5px;
  }
}


.quote_img.wpb_single_image img{max-width:250px; }

h1{
  font-size:81px;
  font-weight:600;
  padding-bottom:0px;
}

section:not(#startseite){
.section-headline{
  text-align: center;
  color:#000;
  z-index:4;

  h1{
    padding-top:0px;
    margin-top:0px;
    font-size:54px;
    font-weight:700;
    padding-bottom:50px;
    position: relative;
    margin-bottom:25px;
  &:after{
    content:"";
    width:62px;
    height:2px;
    background-color:#000;
    position:absolute;
    bottom: 0px;
    left:calc(50% - 31px);
  }
  }

}

}

#picto_wrapper{
  max-width: 680px;
.wpb_single_image{margin-bottom: 10px;}
.wpb_text_column{font-size:20px;font-weight:300;text-align:center;margin-bottom:0px;}
.arrow{
  margin:0px;
  padding-top:70px;
  padding-bottom: 70px;
  &.arrow-npt{
    padding-top:39px;
  }
  &.arrow-left-single{
    padding-top:25px;
  }
  img{
    width:30px!important;
  }
}

.center-picto{
  margin-top:160px;
}


}


.mehr_btn{
  text-align: center;
  margin-bottom:140px;
a{
  border-radius:5px;
  border:1px solid #c8caca;
  padding:12px 30px 12px 30px;
}
}

.image-left{
  padding-right:50px;
}

.zoom_image{
  width:100%;
  .magnify,img{
    width:100%;
  }

}

.margin-top{
  margin-top:150px;
}

.uavc-list{
list-style-type: none;
font-size: 16px;
line-height:26px;
max-width:528px;
width:100%;
margin-left:auto;
margin-right:auto;
.uavc-list-icon{
  width:15px;
  display:inline-block;
  margin-right:20px;
  float:left;
  clear:left;

}
}


.preis{
  h1{
    color:rgb(0,66,106);
    font-weight: 800;
  }
}

#startseite{
  .section-headline{
    position: absolute;
    width: 100%;
    top:255px;
    text-align: center;
    color:#fff;
    z-index:4;

    h1{
      font-size:81px;
      font-weight:600;
      padding-bottom:0px;
    }
    h2{
      font-size:29px;
      font-weight:600;
    }

    p{
      margin-top:56px;
      font-size:29px;
      font-weight:300;
    }

    .cta-button {
      width:100%;
      text-align:center;
      a{
      background-color:$primary;
      border:1px solid $primary;
      border-radius:5px;
      color:#fff;
      width:255px;
      height:50px;
      font-size:24px;
      line-height:50px;
      text-transform:uppercase;
      font-weight:bold;
    }

    .wpb_text_column p{
      font-size: 16px;
      font-weight:300;
    }

  }



  }
}

#inhalte-des-basispakets,.konfigurator-auswahl{
  .basis-headline{
    font-siye:18px;
    font-weight:600;
    color:#fff;
  }
  .woocommerce{
    background-color:#fff;
    text-align:center;
    min-height:312px;
    @media (max-width:876px){
      margin-bottom:30px;
    }
    .price{
      display:none;
    }
    .button{
      opacity:0;
      border-radius:5px;
      border:1px solid #c8caca;
      padding:12px 30px 12px 30px;
      background-color:#fff;
    }
    a:not(.button){
      width:100%;
      text-align:Center;
      display:inline-block;
    }
    li{
      width:100%;
      text-align:Center;
    }
    img{
      height:180px;
      width:auto;
      margin-right:auto;
      margin-left:auto;
    }
    h3{
      font-weight:300;
      font-size:16px;
      color:#000;
    }

      li.product{
      margin-top:50px;
      margin-bottom:25px;

    }
    &:hover,&.selectedCat{
      -webkit-box-shadow: 0px 2px 10px -3px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 2px 10px -3px rgba(0,0,0,0.75);
box-shadow: 0px 2px 10px -3px rgba(0,0,0,0.75);

      .button{
        opacity: 1;
      }
    }
  }
}

.konfigurator-auswahls .woocommerce:hover {
    box-shadow: 0 2px 40px -3px rgba(0, 0, 0, .55);
}

.konfigurator-auswahl .woocommerce a.button:hover,.konfigurator-auswahl .woocommerce.selectedCat a.button{
    background-color:$primary;
    color:#fff;
}

.konfigurator-auswahl{
  .cta-text{
    top: 10px;
position: absolute;
left: -10px;
font-weight: 600;
width: 100%;
  }
}

.paket_info_left{
  margin-top:30px;
  font-size:14px!important;
  font-weight:300;
  .uavc-list{
    padding-left:10px;
    font-size:14px;
    .img-icon{
      vertical-align: top;
      margin-top: 6px;
    }
  }
}

.paket_info_right{
  margin-top:30px;
  .button{
  border-radius: 5px;
border: 1px solid #c8caca;
padding: 12px 30px;
background-color: transparent;
}
h3{
margin-top:0px;
  margin-bottom:10px;
  font-weight:600;
  font-size:35px;
}
h2:after{
  display:none
}
}

#kontakt{
  margin-left:auto;
  margin-right: auto;
padding-bottom:140px;
  #gform_wrapper_2{
    max-width:580px;
    margin-left:auto;
    margin-right:auto;
  input[type=text],textarea{
    background-color: transparent;
    border:1px solid #d1d2d2;
  }
  textarea{
    height:62px;
  }
  label{
    display:none;
  }
  input[type=submit]{
    background-color:transparent;
    border:1px solid $primary;
    border-radius:5px;
    color:$primary;
    width:255px;
    height:50px;
    font-size:24px;
    line-height:50px;
    text-transform:uppercase;
    font-weight:bold;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top:56px;
  }
  }
}

#ueber{
  position:relative;
  padding-top:150px;
  .about-logo{
    position:absolute;
    top:-90px;
    width:100%;
    text-align:center;
    max-width:100%;
    img{
      max-width:158px;
    }

  }
  p{
    max-width:580px;
    font-size:16px;
    font-weight:300;
    margin-left: auto;
    margin-right: auto;
  }
}

#footer-1,#footer-2{
  color:#fff;
}

#footer-2{
  h2{
    font-size: 36px;
    line-height:45px;
    font-weight: 700;
    margin-bottom:0px;
  }
  h3{
    font-size:54px;
    line-height:70px;
    font-weight: 700;
    margin-top:0px;
  }
  p{
    font-size: 16px;
    font-weight: 300;
  }
  img{
    margin-top: 30px;
  }
}




body.konfigurator-und-bestellung,body.konfigurator,body.warenkorb,body.checkout,body.abschluss,body.konfigurator-auswahl,body.konfigurator-mini{
background-color: #f1f1f2;
  .main{
    margin-top:0px;
  }
  h1{
    text-align:center;
    margin-top:80px;
    margin-bottom:65px;
  }
  h2{
    text-align:center;
    margin-bottom:80px!important;
    position:relative;
    &:after{
      content:"";
      width:62px;
      height:2px;
      background-color:#000;
      position:absolute;
      bottom: -40px;
      left:calc(50% - 31px);
    }
  }
  .pro-wrapper{
    background-color:#fff;
    border:1px solid #80b5d0;
    padding:16px 32px 16px 32px;
    margin-bottom:17px;
    font-size:14px;
    img{
      margin:0px;
    }
    img{
      max-width: 50px;
    }
    .wpb_button, .wpb_content_element, ul.wpb_thumbnails-fluid > li{
      margin:0px;
    }
    input[type=number]{width:80%;}
    input[type=checkbox]{float:right;}
    div[class*=" checked-"] {display:none;float:right;margin-top: 5px;
margin-left: 50px;}
    div.active[class*=" checked-"]{display:block;}
    input[type=checkbox].active{display:none;}
    .price-wrp{float:right;display:block;}

   .produkt_info{
     display:inline-block;
     margin-left:3px;
     width:15px;
     height:14px;
     background-image:url("../images/info_btn.png");
     background-repeat:no-repeat;
     background-position: center center;
     overflow:hidden;
     text-indent:-100px;
     margin-bottom:-2px;
   }

   .vc_column_container > .vc_column-inner{
      .price-wrp span{
        display:inline-block;
      }
      label,p,input,.vc_align_right img,.price-wrp span{
       margin-top:12px;

      }
      input[type=checkbox]{
        margin-top:20px;
      }
      p.bigger{
        margin-top:0px;
      }

   }

  }

  .orders{
    .pro-wrapper{
        border:1px solid #e3e3e3;
        &.ordered{
            border:1px solid #80b5d0;
        }
    }
  }

  .status-control{
    font-size:16px;
    padding-top:40px;
    padding-bottom:40px;
    padding-left:0px;
    padding-right:0px;
    background-color:#fff;
  .wpb_button, .wpb_content_element, ul.wpb_thumbnails-fluid > li{
    margin-bottom:0px;
  }
  .sum_wrapper{
    text-align:right;

  }

  .zzgl{
    font-size:12px;
  }

  #sum{
    font-size:32px;
    font-weight:700;
  }

  .btn_next{
    background-color:$primary;
    border-radius:5px;
    border:1px solid $primary;
    padding:12px 30px 12px 30px;
    color:#fff;
    width:116px;
    text-align:Center;
    clear:both;
    cursor:pointer;
  }

  .btn_order{
    background-color:$primary;
    border-radius:5px;
    border:1px solid $primary;
    padding:12px 16px 12px 16px;
    color:#fff;
    width:150px;
    text-align:Center;
    clear:both;
    cursor:pointer;
    display:none;
  }

 }

 .gfield.col-sm-4{
   width:33.33%;
 }

  label.gfield_label{
    display:none!important;
  }

 .field_sublabel_below{
   float:left;
   clear:none!important;
   margin-bottom:30px;
   &.gfield_html_formatted{
     clear:both!important;
     text-aligin:center!important;
     width:100%;
   }
   input,select{
     width:100%;
     background-color:transparent;
     border:1px solid #dcddde;
   }

 }

 input.error, .error{
   border:1px solid #ff0000;
 }

 #field_3_11{
   clear:left!important;
 }

 #gform_submit_button_3{
   display:none;
 }

 .btn_back{
   color:$primary;
 }

 .btn-next2,.btn-order{
   display:none;
 }

 .step-1,.step-2,.step-3,.step-4{
   display:none;
 }
 .step-1.active,.step-2.active,.step-3.active,.step-4.active{
   display:block;
 }



}

.konfigurator,.konfigurator-mini{
  .single-product > .product > .images{display:none;}
  .woocommerce div.product div.summary{
    float:none;
    width:100%;
  }
  input[name="mnm_quantity[633]"],input[name="mnm_quantity[724]"]{display:none;}
  .mnm_cart.cart{display:none;}
  .woocommerce-Price-amount{display:none;}
  h1.product_title{display:none;}

  div[class*=" checked"] {display:none;float:right;margin-top: 5px;
margin-left: 50px;}
  div.active[class*=" checked"]{display:block;}
  input[type=checkbox].active{display:none;}

  .checked img{margin-top:12px;}
  .woocommerce div.product p.price, .woocommerce div.product span.price{color:#000;font-size:14px;margin-right:20px;margin-top:12px;}
  .woocommerce {
    label{
      margin-top:12px;
      a{color:#000;}
    }

  }

  div[itemprop='offers']{opacity:0;}
}

.warenkorb{
  .cart_item.mnm_table_container{display:none!important;}
  table.cart td.product-thumbnail{
      padding:16px 32px;
  }
  table.cart td{
    border-top:1px solid #80b5d0!important;
    border-bottom:1px solid #80b5d0!important;
  }
  table.cart{
    td.actions{display:none;}
      .product-remove{display:none;}
    .product-thumbnail{
        border-left:1px solid #80b5d0!important;
    }
    .product-subtotal{
        border-right:1px solid #80b5d0!important;
    }
    .mnm_table_item:not(.mnm_table_item_last) td, .shop_table .mnm_table_item:not(.mnm_table_item_last) td{
        border-bottom:1px solid #80b5d0!important;
    }
  }
  .cart .mnm_table_container td, .shop_table .mnm_table_container td{
    border-bottom:1px solid #80b5d0!important;
  }
  table.cart img{
    max-width:50px;
    width:50px;

  }
  .td.actions,.shop_table thead{display:none;}
  /*td.product-remove{display:none;}*/
  .woocommerce .woocommerce-message{
    display:none;
  }
  .woocommerce table.shop_table{
    background-color:#fff;
    margin: 0 -1px 24px 0;
text-align: left;
width: 100%;
border-collapse: separate;
border-radius: 0px;
border:1px solid #80b5d0;
  }
.woocommerce table.shop_table.cart{
    background-color:transparent;
    margin: 0 -1px 24px 0;
text-align: left;
width: 100%;
border-collapse: separate;
border-radius: 0px;
border: none;
border-collapse: separate;
border-spacing: 0 17px;
  }
  .cart_item{
    background-color: #fff;
border: 1px solid #80b5d0;
padding: 16px 32px;
margin-bottom: 17px;
font-size: 14px;
  }
  .woocommerce #respond input#submit.alt, .woocommerce a.button.alt, .woocommerce button.button.alt, .woocommerce input.button.alt{background-color:$primary;font-weight:300;}

  .checkout-button{
    display:none !important;
  }
}

.checkout{


    .woocommerce{
      .col2-set .col-1{
        float: left;
       width: 100%;
      }

    }

  .woocommerce-shipping-fields h3{display:none;}
  .woocommerce form .form-row input.input-text, .woocommerce form .form-row textarea{
    width: 100%;
background-color: transparent;
border: 1px solid #dcddde;
padding: 1em .5em 1em 0;
text-indent: .5em;

  }

  .form-row.place-order{
    display:none;
  }

  .woocommerce-thankyou-order-received{
    margin-top: 50px;
font-size: 25px;
text-align: center;
margin-bottom: 50px;
  }
}


.steps-wrapper{
  font-size:24px;
  font-weight:300;
  color:rgb(204,204,204);
  background-color:#fff;
}

.step-btn-1,.step-btn-2,.step-btn-3,.step-btn-4{
  position:relative;
  &.active{
    color:$primary;
  }
  &:after{
    content:"";
    width:88px;
    height: 100%;
    background-image: url("../images/steps-arrow.png");
    background-repeat: no-repeat;
    display:inline-block;
    margin-left:5px;
    position:absolute;
    right:-5px;
    top:0px;
    background-position:center center;
  }
  .wpb_button, .wpb_content_element, ul.wpb_thumbnails-fluid > li{
    margin-bottom:0px;
  }
  p{
    text-align:center;
    padding-top:60px;
    padding-bottom:60px;
  }
}

.step-btn-4{
  &:after{
   display:none;
  }
}



.step-1-page{
  .step-1-no{
    display:none;
  }
}
.step-3-page{
  .step-3-no{
    display:none;
  }

  .orders .pro-wrapper{
    display:none;
  }
  .orders .pro-wrapper.ordered{
    display:block;
  }
}

.featherlight .featherlight-content{
  overflow:hidden;
}
.featherlight .featherlight-close-icon {
    position: absolute;
    z-index: 9999;
    top: 9px;
    right: 24px;
    line-height: 25px;
    width: 25px;
    cursor: pointer;
    text-align: center;
    font-family: Arial,sans-serif;
    background: #fff;
    background: hsla(0,0%,100%,.3);
    color: #000;
    font-size: 30px;
    opacity: 0.4;
}


.featherlight-inner{
  width:900px;
  height:900px;
  overflow:hidden;
}

.featherlight-iframe{
   .featherlight-inner{
  width:100%;
  height:900px;
  overflow:hidden;
}
.featherlight-content{
  width:90%;
}

}

.woocommerce-breadcrumb{
  display:none;
}

div.featherlight-content{
  .images{
    text-align:center;
  }
  div[itemprop=offers],.cart,.woocoomerce-tabs,.related{
    display:none;
  }
  h1{
    text-align:center;
  }
}


.ueberwachung,.alarm-und-meldeanlagen,.steuerung{

  background-color: #f1f1f2;
  .main{
    margin-top:0px;
    padding-top:0px;
  }
  .categories_wrapper {
    padding-left:20px;
    padding-right:20px;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
    background-color: rgb(255, 255, 255);
    margin-bottom: 20px;
    a{
      margin-right:20px;
    }
}

  .basis-headline{
    font-siye:18px;
    font-weight:600;
    color:#fff;
  }
  .woocommerce .product{
    background-color:#fff;
    text-align:center;
    min-height:312px;
    .price{
      display:none;
    }
    .button{
      opacity:0;
      border-radius:5px;
      border:1px solid #c8caca;
      padding:12px 30px 12px 30px;
      background-color:#fff;
    }
    a:not(.button){
      width:100%;
      text-align:Center;
      display:inline-block;
    }
    li{
      width:100%;
      text-align:Center;
    }
    img{
      height:180px;
      width:auto;
      margin-right:auto;
      margin-left:auto;
    }
    h3{
      font-weight:300;
      font-size:16px;
      color:#000;
    }

      li.product{
      margin-top:50px;
      margin-bottom:25px;

    }
    &:hover{
      -webkit-box-shadow: 0px 2px 10px -3px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 2px 10px -3px rgba(0,0,0,0.75);
box-shadow: 0px 2px 10px -3px rgba(0,0,0,0.75);

      .button{
        opacity: 1;
      }
    }
  }

  .woocommerce ul.products li.product a img {
    width: auto;
    height: auto;
    display: block;
    margin: 0 0 1em;
    box-shadow: none;
    max-height: 160px;
    min-height:160px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
}
}

#komponenten .mehr_btn{
/*  display:none;*/
}

#picto_mobile,.einsatz_mobile{
  display:none;
}

.payment_method_payzenstd img{min-width:240px;}

.show_mobile{display:none;}


@media (max-width: 1200px) {
  header.scrolled #main_navi li{
    float:left;
  }
  body header.scrolled .logo {
    max-width: 130px;
    margin-top: -50px!important;
    opacity: 1;
    height: auto;
    left:50%;
}

header.banner{
  height:150px;
}

.main{
  margin-top:150px;
}

body{
  .logo{
    top: 110px;
  }
  #main_navi{
    ul{
    width:80%;
     }
    li{
      margin-top:50px;
    }
  }
}


}

.mm-listview>li>a, .mm-listview>li>span{
  text-transform:uppercase;
}


@media (max-width: 1090px) {

section#ueber{
  overflow:visible;
}

body{
.featherlight-iframe{
  .featherlight-content{
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
}
}

.featherlight-iframe .featherlight-inner{
  height: 100%;
  width: 100%;
  overflow:visible;
}

.featherlight-inner{
  width:100%;
}
}


section{
  overflow:hidden;
}
  .full-width.hero-image{
    margin-bottom:100px;
  }

body header.scrolled .logo,
body header .logo{
  width: 100px;
position: absolute;
top: 3px;
left: 50%;
transform: translatex(-50%);
margin-top:0px!important;
.logo-blue{
  display:block!important;
}
.logo-white{
  display:none!important;
}
}
  header.banner{
    height:120px;
  }

  .main{
    margin-top:120px;
  }


 #main_navi{
   display:none;
 }
 .nav_btn{
   display:block;
   top:30px;
 }

 .step-btn-1 p, .step-btn-2 p, .step-btn-3 p, .step-btn-4 p {
    padding-top: 0px;
    padding-bottom: 0px;
    font-size:2vw;
}

.step-btn-1::after, .step-btn-2::after, .step-btn-3::after, .step-btn-4::after{
  display:none;
}

}

@media (max-width:876px){
  .show_mobile{
    display:block;
    .uavc-list {
    margin-left: 0;
    padding-left: 0px;
  }
}
  .hide_mobile{display:none;}
}
@media (max-width: 800px) {


body{


  .woocommerce-main-image.zoom img{
    width:100%;
    max-width:336px;
    height:auto;
  }


.featherlight .featherlight-content{
  width:95%;
}

.featherlight .type-product .product_title{
  font-size:8vw;
}

  .full-width.hero-image .wpb_single_image  img{
    min-width:800px;
margin-left: 50%;
transform: translate( -50%, 0);
}

#startseite .section-headline {
    top: 0px;

}

.hero-image{
  margin-bottom:100px;
}

#startseite .section-headline h1,section:not(#startseite) .section-headline h1{
  font-size:10vw;
}


#picto_wrapper .vc_column_container{
  width: 33.33333333%;
  float:left;
}

#picto_wrapper{
display:none;
}
#picto_mobile{
  display:block;
}

#startseite .arrow-down img,.arrow-down img {
    width: 9vw;
    height: auto;
    max-width: 65px;
}

.step-btn-1, .step-btn-2, .step-btn-3, .step-btn-4{
  width:25%!important;
  float:left!important;
}

&.page-template-template-konfigurator{
  .status-control{
  position:relative;
}
}

}


body.konfigurator-und-bestellung .main h1,
body.konfigurator-auswahl .main h2,
body.konfigurator .main h1,
body.warenkorb .main h1{
  font-size:8vw;
}

body.konfigurator-und-bestellung .main h2,
body.konfigurator-auswahl .main h2,
body.konfigurator .main h2,
body.warenkorb .main h2{
  font-size:6vw;
}

body.konfigurator-und-bestellung,
body.konfigurator-auswahl,
body.konfigurator,
body.warenkorb{
  .pro-wrapper{
    text-align:center;
  }

  .wpb_single_image.vc_align_left{
    text-align:center;
    margin-bottom:30px;
  }

  .wpb_single_image.vc_align_right {
    text-align: center;
    margin-top:30px;
    margin-bottom:30px;
}

.pro-wrapper input[type=number] {
    width: 80%;
    max-width: 90px;
    max-width: 1;
    text-align: Center;
    margin-top:30px;
}

.pro-wrapper div[class*=" checked-"] {
    display: none;
  float: none;
    margin-left: inherit;
    margin-top: 30px;
}

.pro-wrapper input[type=checkbox]{
  float:none;
  margin-top:30px;
  margin-bottom:30px;
}

.status-control .sum_wrapper {
    text-align: center;
    margin-top:30px;
    margin-bottom:30px;
}

.status-cotrol .wpb_column{
  text-align:center;
}

.status-control .btn_next, .status-control .btn_order{
  width:100%;
}

.gfield.col-sm-4 {
    width: 100%;
}

.steps-wrapper{
  padding-top: 40px;
padding-bottom: 10px;
}



}

}

@media (max-width: 550px) {
  body{
    .nav_btn_txt{display:none;}
    .nav_btn{
      left:20px;
      background-color:$primary;
      span{
        color:#fff;
      }
      .hamburger-inner, .hamburger-inner::after, .hamburger-inner::before{
        background-color:#fff;
      }
    }

.cta-button.cta-button-3 a {width: 95%;height: auto;padding-left: 20px;padding-right: 20px;}

.zoom_image{
  display:none;
}
.einsatz_mobile{
  display:block;
  img{
    width:100%;
    height:auto;
  }
}

.step-btn-1 p, .step-btn-2 p, .step-btn-3 p, .step-btn-4 p {
   padding-top: 0px;
   padding-bottom: 0px;
   font-size:3vw;
}




  }




}
