.logo{
max-width:260px;
margin-left:auto;
margin-right:auto;
margin-top:-80px;
position:relative;
position: absolute;
left: calc(50% - 130px);

img{
	max-width:260px;
	height:auto;
  &.logo-blue{
	   display:none;
	}
	&.logo-white{
		display:block;
	}


}

}
.nav_btn{
position:absolute;
width:auto;
max-width:1170px;
margin-left:auto;
margin-right:auto;
text-align:right;
color:#000;
display:none;
left:30px;
top:45%;
a{color:#000;}

}

header.banner{
	transform: translateZ(0);
-moz-transform: translatez(0);
-ms-transform: translatez(0);
-o-transform: translatez(0);
-webkit-transform: translateZ(0);
-webkit-font-smoothing: antialiased;
	position:absolute;
	top:0px;
	left:0px;
	width:100%;
	z-index:2;
	background-color:#fff;
	height:196px;
}




.nav-primary.mm-menu{
  opacity:1;
}

.nav_btn{
  text-transform:uppercase;
  font-size:18px;
}

.arrow-down{
  width:100%;
  text-align:center;
  margin-top:80px;
}

#main_navi{

  ul{
   display: inline-block;
  }
  li{
  float:left;
  margin-top:112px;
  font-size:24px;
  text-transform: uppercase;
  margin-right:22px;
  &.big{
    margin-right: 320px;
  }

	a{
    color:#000;
    font-weight:600;
    padding-top:0px;
    padding-bottom:0px;
    padding-left:0px;
    padding-right:0px;
    line-height:0px;
  }

}

}
